@font-face {
    font-family: "Poppins";
    src: url("../../assets/font/Poppins.ttf") format("truetype");
}

*, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', serif;
    background-color: #FCFCFC;
    overflow-y: hidden;
}